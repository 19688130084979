<template>
  <div v-if="!isDeleted">
    <Button
      v-if="isFollowingFlag"
      :onClick="() => followUser(false)"
      :text="$t('common.following')"
      class="w-full"
      type="small"
      variant="white-blue"
      >{{ $t('common.following') }}
    </Button>
    <Button v-else :onClick="() => followUser(true)" :text="$t('common.follow')" class="w-full" type="small" variant="light">
      {{ $t('common.follow') }}
    </Button>
  </div>
</template>
<script lang="ts" setup>
import {useUserUpdateFollower} from '~/composables/user'
import {GTM_EVENT_FOLLOW_USER, GTM_EVENT_TYPE_CLICK} from '~/constants/gtmEvent'
import {useIsUserInSession} from '~/composables/auth/useStateCSpotSession'

interface ComponentProps {
  isFollowing: boolean
  userId: string
  isDeleted: boolean
  guestGtmNotification?: string
}

const props = defineProps<ComponentProps>()

const i18n = useI18n()
const {$eventTracker, $showErrorToast, $showSuccessToast} = useNuxtApp()
const thereIsSession = useIsUserInSession()

const isFollowingFlag = ref<boolean>(props?.isFollowing || false)

const followUser = async (value: boolean) => {
  if (!thereIsSession.value && props.guestGtmNotification) {
    $eventTracker.triggerEvent(props.guestGtmNotification, GTM_EVENT_TYPE_CLICK)
  }

  const {error} = await useUserUpdateFollower(props?.userId, value)
  if (error && error.value) {
    $showErrorToast(error)
  } else {
    isFollowingFlag.value = value

    const translatedMessage = i18n.t(value ? 'common.userFollowedResponse' : 'common.userUnfollowedResponse')
    $showSuccessToast(translatedMessage)
    if (value) {
      $eventTracker.triggerEvent(GTM_EVENT_FOLLOW_USER, GTM_EVENT_TYPE_CLICK)
    }
  }
}
</script>
